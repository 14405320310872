import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

const NewsletterContainer = styled.div`
  background: rgba(0, 0, 0, 0.85);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.7);
  width: 100%;
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 600px) {
    padding: 1.5rem;
    max-width: 90%;
  }
`;

const Input = styled.input`
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.2rem;
  text-align: left;
  font-family: 'Lora', serif;
  caret-color: #fff;
  width: 100%;
  max-width: 250px;
  margin-bottom: 1rem;

  &::placeholder {
    color: #888;
  }
`;

const ThankYouMessage = styled.p`
  font-size: 1.2rem;
  color: #fff;
  font-family: 'Lora', serif;
  text-align: center;
`;

const Newsletter = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleNewsletterSubmit = async (e) => {
    e.preventDefault();
    if (!email || !email.includes('@')) {
      return; // Do nothing if invalid email
    }
    try {
      await addDoc(collection(db, 'newsletterSubscribers'), { 
        email,
      timestamp: serverTimestamp(),
    });
      setSubmitted(true);
    } catch (error) {
      console.error('Error adding email to Firestore:', error);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form behavior
      handleNewsletterSubmit(e); // Trigger the form submission
    }
  };

  if (submitted) {
    return (
      <NewsletterContainer>
        <ThankYouMessage>Thank you.</ThankYouMessage>
      </NewsletterContainer>
    );
  }

  return (
    <NewsletterContainer>
      <form
        onSubmit={handleNewsletterSubmit}
        style={{ width: '100%', textAlign: 'center' }}
      >
        <Input
          ref={inputRef}
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyDown} // Explicitly handle Enter/Return
        />
      </form>
    </NewsletterContainer>
  );
};

export default Newsletter;
