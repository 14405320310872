// src/components/SecurityAdvice.js
import React from 'react';
import { ArtContainer, ArtText } from '../../../styles/secureLoginStyles';

const Music = () => {
  return (
    <ArtContainer>
      <ArtText>
        <h3></h3>
        <p>
        </p>
        <ul>
          <li>
            <strong>To be announced</strong>
          </li>
        </ul>
        <p>
        </p>
      </ArtText>
    </ArtContainer>
  );
};

export default Music;
