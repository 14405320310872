// src/firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD5Ns1-sE8FmeWVwj_VPidyOoRztakY4KA",
  authDomain: "ian-bloom.firebaseapp.com",
  projectId: "ian-bloom",
  storageBucket: "ian-bloom.firebasestorage.app",
  messagingSenderId: "715479261299",
  appId: "1:715479261299:web:1dbf75ae0391ecf696ca06",
  measurementId: "G-GNH5WWEBK9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
export const storage = getStorage(app);

const db = getFirestore(app);

export { auth, db };