import React, { useState } from 'react';
import {
  LoginWrapper,
  LoginContainer,
  LogoContainer,
  Logo,
  LoginTitle,
  AnimatedField,
  FooterOverlay,
  FooterLink,
  HeaderLinks,
  HeaderLinksWrapper,
  HeaderLink,
} from '../../styles/secureLoginStyles';
import Art from './sections/Art';
import Film from './sections/Film';
import Literature from './sections/Literature';
import Music from './sections/Music';
import About from './sections/About';
import Disclaimer from './sections/Disclaimer';
import SocialMedia from './sections/SocialMedia';
import Newsletter from './sections/Newsletter';
import LogoImage from '../../assets/images/logo.png';

const currentYear = new Date().getFullYear();

const Portfolio = () => {
  const [view, setView] = useState('home');
  const loginTitle = '';

  const renderContent = () => {
    switch (view) {
      case 'art':
        return <Art />;
      case 'film':
        return <Film />;
      case 'literature':
        return <Literature />;
      case 'music':
        return <Music />;
      case 'about':
        return <About />;
      case 'disclaimer':
            return <Disclaimer />;
      case 'social media':
          return <SocialMedia />;
      case 'newsletter':
          return <Newsletter />;
      default:
        return (
          <>
            <LoginTitle>{loginTitle}</LoginTitle>
            <p style={{ textAlign: 'center', fontSize: '1.1rem', marginTop: '1rem' }}>
              {/* Explore the works of Ian Bloom by selecting a category below. */}
            </p>
          </>
        );
    }
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <LogoContainer onClick={() => setView('home')} style={{ cursor: 'pointer' }}>
          <Logo src={LogoImage} alt="Ian Bloom Logo" />
        </LogoContainer>
                <AnimatedField>
          {renderContent()}
          <HeaderLinksWrapper>
            <HeaderLink
              onClick={() => setView('film')}
              style={{ fontWeight: view === 'film' ? 'bold' : 'normal' }}
            >
              Film
            </HeaderLink>
            <HeaderLink
              onClick={() => setView('literature')}
              style={{ fontWeight: view === 'literature' ? 'bold' : 'normal' }}
            >
              Literature
            </HeaderLink>
            <HeaderLink
              onClick={() => setView('music')}
              style={{ fontWeight: view === 'music' ? 'bold' : 'normal' }}
            >
              Music
            </HeaderLink>
          </HeaderLinksWrapper>
        </AnimatedField>

      </LoginContainer>
      <FooterOverlay>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => setView('home')}
          >
            &copy; {currentYear}
          </div>
          <div style={{ display: 'flex', gap: '1.5rem' }}>
            <FooterLink
              onClick={() => setView('about')}
              style={{ fontWeight: view === 'about' ? 'bold' : 'normal' }}
            >
              Biography
            </FooterLink>
            <FooterLink
              onClick={() => setView('disclaimer')}
              style={{ fontWeight: view === 'disclaimer' ? 'bold' : 'normal' }}
            >
              Terms
            </FooterLink>
            <FooterLink
              onClick={() => setView('social media')}
              style={{ fontWeight: view === 'social media' ? 'bold' : 'normal' }}
            >
              Social Media
            </FooterLink>
            <FooterLink
              onClick={() => setView('newsletter')}
              style={{ fontWeight: view === 'newsletter' ? 'bold' : 'normal' }}
            >
              News
            </FooterLink>


          </div>
        </div>
      </FooterOverlay>
    </LoginWrapper>
  );
};

export default Portfolio;
