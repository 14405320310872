import React from 'react';
import { ArtContainer, ArtText } from '../../../styles/secureLoginStyles';
import ProfileImage from '../../../assets/images/profile.png'; // Adjust the path as necessary

const About = () => {
  return (
    <ArtContainer>
      <div style={{ textAlign: 'center', marginBottom: '1.5rem' }}>
        <img 
          src={ProfileImage} 
          alt="Ian Bloom" 
          style={{ width: '222px', height: 'auto', borderRadius: '4px' }} // Keeps the image rectangular
        />
      </div>
      <ArtText>
        <p>
          Ian Bloom is an American artist born on February 15, 1990. Working in concert with collectors, Bloom markets 
          abstract and realistic paintings, alongside photographs, drawings, films, music, fashion, and literature.
        </p>
        <p>
          Bloom was born on Sunset Boulevard in Hollywood, Los Angeles, California, the only child of an interracial marriage. 
          His mother, Akiko, from Japan, worked for a prominent Japanese bank, and his father, Barry, an American, is a renowned 
          cardiac anesthesiologist. They met in Mexico during graduate school, later moving to Washington, D.C., then New York, 
          and finally Los Angeles. His Ashkenazi Jewish great-grandparents immigrated from Russia, Poland, and Germany; his 
          grandparents and his father were born in New York.
        </p>
        <p>
          Growing up off of Mulholland Drive in Los Angeles, Bloom spent vacations with his grandparents in the town of Miyazaki 
          near Toyama, and alternated his time between Japan and Europe. His travels through Italy, Switzerland, France, Monaco, 
          Spain, England, Austria, and Vatican City shaped his global perspective and artistry.
        </p>
        <p>
          At age 16, Bloom completed Art History and Film Studies courses at Columbia University, followed by Political Science 
          and Comparative Literature courses at the University of California, Berkeley, at 17. Between 2008 and 2013, he pursued 
          a double major in Accounting and Marketing at New York University's Stern School of Business and graduated with Bachelor's 
          degrees in both disciplines, and a Master of Science degree in Accounting in 2013.
        </p>
        <p>
          Bloom has worked as an actor and as a Certified Public Accountant, but got his start in the art world selling his literature 
          online. With an original vision and tactical execution, Bloom continues to expand his portfolio, driven by a relentless pursuit 
          of excellence, innovation, and aesthetic mastery.
        </p>
      </ArtText>
    </ArtContainer>
  );
};

export default About;
