import React from 'react';
import { ArtContainer, ArtText } from '../../../styles/secureLoginStyles';

const SocialMedia = () => {
  return (
    <ArtContainer>
      <ArtText>
          <ul>
          <li>
            <a
              href="https://www.instagram.com/ianbloomofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/@ianbloomofficial"
              target="_blank"
              rel="noopener noreferrer"
            >
              YouTube
            </a>
          </li>
          <li>
            <a
              href="https://imdb.me/ianbloom"
              target="_blank"
              rel="noopener noreferrer"
            >
              IMDb
            </a>
          </li>
          <li>
            <a
              href="https://www.amazon.com/stores/author/B0DJHQ23FS"
              target="_blank"
              rel="noopener noreferrer"
            >
              Amazon
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/ian-bloom"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </li>
        </ul>
      </ArtText>
    </ArtContainer>
  );
};

export default SocialMedia;
