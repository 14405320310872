// src/components/SecurityAdvice.js
import React from 'react';
import { ArtContainer, ArtText } from '../../../styles/secureLoginStyles';

const Art = () => {
  return (
    <ArtContainer>
      <ArtText>
        <h3></h3>
        <p>
        </p>
        <ul>
          <li>
            <a href="https://example.com/book1" target="_blank" rel="noopener noreferrer">
              <strong>Driver</strong> (2016)
            </a>
          </li>
          <li>
            <a href="https://example.com/book2" target="_blank" rel="noopener noreferrer">
              <strong>Star Vehicle</strong> (in post-production)
            </a>
          </li>
          <li>
            <a href="https://example.com/book3" target="_blank" rel="noopener noreferrer">
              <strong>Fate</strong> (in development)
            </a>
          </li>
        </ul>
        <p>
        </p>
      </ArtText>
    </ArtContainer>
  );
};

export default Art;
