import React from 'react';
import { ArtContainer, ArtText, PosterImage } from '../../../styles/secureLoginStyles';
import DriverPoster from '../../../assets/images/driver.jpg';
import StarVehiclePoster from '../../../assets/images/starvehicle.jpg';
import FatePoster from '../../../assets/images/fate.jpg';

const Film = () => {
  return (
    <ArtContainer>
      <ArtText>
        <h3></h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '1rem' }}>
          {/* Driver */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <a href="https://www.imdb.com/title/tt31259587/" target="_blank" rel="noopener noreferrer">
              <PosterImage src={DriverPoster} alt="Driver Poster" />
            </a>
            <a
              href="https://www.imdb.com/title/tt31259587/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: "'Lora', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Driver (2016)
            </a>
          </div>

          {/* Star Vehicle */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <a href="https://www.imdb.com/title/tt33165082/" target="_blank" rel="noopener noreferrer">
              <PosterImage src={StarVehiclePoster} alt="Star Vehicle Poster" />
            </a>
            <a
              href="https://www.imdb.com/title/tt33165082/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: "'Lora', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Star Vehicle (in post-prod)
            </a>
          </div>

          {/* Fate */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <a href="https://www.imdb.com/title/tt33500124/" target="_blank" rel="noopener noreferrer">
              <PosterImage src={FatePoster} alt="Fate Poster" />
            </a>
            <a
              href="https://www.imdb.com/title/tt33500124/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: "'Lora', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Fate (in development)
            </a>
          </div>
        </div>
      </ArtText>
    </ArtContainer>
  );
};

export default Film;
