import React from 'react';
import { ArtContainer, ArtText } from '../../../styles/secureLoginStyles';

const Disclaimer = () => {
  return (
    <ArtContainer>
      <ArtText>
        <h3>IAN BLOOM</h3>
        <h3>TERMS OF USE</h3>
        <h4>About Us</h4>
        <p>
          This website (<a href="www.ian-bloom.com" target="_blank" rel="noopener noreferrer">www.ian-bloom.com</a>) is operated and maintained by Ian Bloom ("we", "us", "our").
        </p>

        <h4>How to Contact Us</h4>
        <p>
          If you have any questions about this policy or your personal information, please contact <a href="mailto:info@ian-bloom.com">info@ian-bloom.com</a>.
        </p>

        <h4>Acceptance of These Terms</h4>
        <p>
          By using this site, you agree to these Terms of Use. If you do not agree, please refrain from using this site. You should also review our Privacy Policy and Cookie Policy, which govern the use of personal data and cookies on this site.
        </p>
        <p>
          We may revise these terms and policies in the future, so please check this page regularly to stay informed of any changes.
        </p>

        <h4>Changes to and Use of the Site</h4>
        <p>
          We may update or change the site without notice. We do not guarantee uninterrupted access and may restrict access at our discretion. The site is provided "as is," and we are not liable for any modifications or interruptions.
        </p>

        <h4>Intellectual Property</h4>
        <p>
          All content on this site, including text, images, and designs, is owned by or licensed to Ian Bloom. Unauthorized use, reproduction, or distribution is strictly prohibited.
        </p>

        <h4>Links to and from the Site</h4>
        <p>
          You may link to this site in a fair and legal manner that does not damage our reputation. We are not responsible for the content of external links provided on this site.
        </p>

        <h4>Our Liability</h4>
        <p>
          To the fullest extent permitted by law, we disclaim liability for any damages arising from your use of the site or its content. The site is provided for informational purposes only, and we do not guarantee the accuracy or completeness of its content.
        </p>

        <h4>Privacy Policy</h4>
        <p>
          We collect personal data, such as your name and email address, when you contact us. This data is used to respond to inquiries and improve the site. We will not share your personal data with third parties without your consent, except as required by law.
        </p>
        <p>
          If you have any concerns about your data, please contact us at <a href="mailto:info@ian-bloom.com">info@ian-bloom.com</a>.
        </p>

        <h4>Cookie Policy</h4>
        <p>
          This site uses cookies to enhance your browsing experience. Cookies are small files stored on your device that help us analyze site usage and improve functionality. By using this site, you consent to our use of cookies.
        </p>
        <p>
          If you wish to disable cookies, please adjust your browser settings. For more information on cookies, please refer to your browser’s help section.
        </p>

        <h4>Google Analytics</h4>
        <p>
          We use Google Analytics to collect anonymous data about site usage to improve the user experience. You can opt out of Google Analytics by visiting: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a>.
        </p>
      </ArtText>
    </ArtContainer>
  );
};

export default Disclaimer;
