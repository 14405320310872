import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { GlobalStyle } from './styles/secureLoginStyles';
import Portfolio from './components/Portfolio/Portfolio'; // Your main portfolio component

function App() {
  const [view, setView] = useState('home');

  return (
    <>
      <GlobalStyle />
      <Router>
        <Routes>
          {/* Define routes for your portfolio */}
          <Route path="/" element={<Portfolio />} />
          <Route path="/film" element={<Portfolio view="film" />} />
          <Route path="/literature" element={<Portfolio view="literature" />} />
          <Route path="/music" element={<Portfolio view="music" />} />
          <Route path="/about" element={<Portfolio view="about" />} />
          <Route path="/social-media" element={<Portfolio view="social-media" />} />
          <Route path="/terms" element={<Portfolio view="terms" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
