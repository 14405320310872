import React from 'react';
import { ArtContainer, ArtText, PosterImage } from '../../../styles/secureLoginStyles';
import ScrewdriverCover from '../../../assets/images/screwdriver.jpg';
import SavageRecreationCover from '../../../assets/images/savage.jpg';
import RawCanvasCover from '../../../assets/images/raw.jpg';

const Literature = () => {
  return (
    <ArtContainer>
      <ArtText>
        <h3></h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginTop: '1rem' }}>
          {/* Screwdriver */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <a
              href="https://www.amazon.com/Screwdriver-Ian-Bloom/dp/1944527001"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                PosterImage src={ScrewdriverCover} alt="Screwdriver Cover"
                style={{ width: '80px', height: '120px', borderRadius: '4px' }}
              />
            </a>
            <a
              href="https://www.amazon.com/Screwdriver-Ian-Bloom/dp/1944527001"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: "'Lora', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Screwdriver (2013)
            </a>
          </div>

          {/* Savage Recreation */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <a
              href="https://www.amazon.com/Savage-Recreation-Ian-Bloom/dp/1944527958"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                PosterImage src={SavageRecreationCover}
                alt="Savage Recreation Cover"
                style={{ width: '80px', height: '120px', borderRadius: '4px' }}
              />
            </a>
            <a
              href="https://www.amazon.com/Savage-Recreation-Ian-Bloom/dp/1944527958"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: "'Lora', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Savage Recreation (2014)
            </a>
          </div>

          {/* Raw Canvas */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <a
              href="https://www.amazon.com/Raw-Canvas-Ian-Bloom/dp/1944527028"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                PosterImage src={RawCanvasCover}
                alt="Raw Canvas Cover"
                style={{ width: '80px', height: '120px', borderRadius: '4px' }}
              />
            </a>
            <a
              href="https://www.amazon.com/Raw-Canvas-Ian-Bloom/dp/1944527028"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: '#ffffff',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: "'Lora', sans-serif",
                fontWeight: 'bold',
              }}
            >
              Raw Canvas (2015)
            </a>
          </div>
        </div>
      </ArtText>
    </ArtContainer>
  );
};

export default Literature;
